.main-header {
  display: grid;
  grid-template-columns: 2fr repeat(3, 1fr);

  .button {
    height: 40px;
    line-height: 19px;
  }

  .button--logout {
    display: initial;
    width: fit-content;
    min-width: auto;
    padding: 12px 12px;
  }

  .text-logout {
    display: none;
  }
}
