.table-validation {
  border-spacing: 10px;

  tr {
    height: 28px;

    .date {
      font-size: 12px;
    }
  }
}

.validation--receipt {
  padding-top: 12px !important;
}

.validation--document {
  display: flex;
  padding: 8px 24px 8px 48px;
  border: 1px solid var(--gray-1);
  border-radius: 8px;
  margin-bottom: 8px;

  .validation__label {
    display: flex;
    width: 100%;
    margin-right: 8px;
    align-items: center;

    span {
      margin-left: 8px;
    }
  }

  .validation__cta {
      display: flex;
      flex: 1;
      margin-right: 8px;
  }
}
